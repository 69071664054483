body {
  font-family: 'Raleway', sans-serif;
  margin: 0;
  padding: 0;
}

.header {
  margin-top: 50px;
  display: flex;
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 8px;
  height: 690px;
  justify-content: center;
}

.header-left {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 50px;
}

.header-logo {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

.header-right {
  flex: 0 0 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
}

.header-text {
  font-weight: normal;
  margin: 0;
  font-size: 64px;
}

.header-subtext {
  font-weight: normal;
  margin-top: 20px;
  font-size: 24px;
}

.header-button-link {
  text-decoration: none;
  /* Remove underline from the link */
}

.header-button {
  margin-top: 10px;
  padding: 20px 20px;
  background-color: #5650BE;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 200px;
  text-transform: uppercase;
  font-weight: bold;
}

.header-button:hover {
  text-decoration: underline;
}

.footer {
  margin-top: auto;
  width: 100%;
  background-color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-link {
  text-decoration: none;
  color: #007BFF;
}

.footer-link:hover {
  text-decoration: underline;
}

.privacy {
  margin: 20px auto;
  width: 80%;
}